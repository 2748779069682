<ng-container autoFocus>
  <div class="dialog-container">
    <div class="close-button" *ngIf="!isRequest">
      <button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="d-flex title-close-btn">
      <div>
        <h3 class="dialog-title">{{ reimbursementDetail?.memberId }}</h3>
        <div class="sub-title">Reimbursement summary ID: {{ reimbursementSummaryId | replaceEmptyValue }}</div>
      </div>
      <div>
        <span translate class="margin-right-10" *ngIf="reimbursementDetail?.reimbursementSummary" prefix="Reimbursement status" [reimbursementStatus]="reimbursementDetail?.reimbursementSummary?.status"></span>
        <span translate *ngIf="reimbursementDetail?.status" prefix="Transaction status" [reimbursementStatus]="reimbursementDetail?.status"></span>
      </div>
    </div>
    <div>
      <form [formGroup]="reimburseSummaryForm">
        <div class="row">
          <div class="col-sm-7 col-xl-8 image-viewer-section">
            <div class="row">
              <div class="col-12" id="image-viewer-section">
                <c-receipt-image-viewer #salesReceiptViewer [imageViewerId]="imageViewerId" [isReimbursement]="true" [reimbursementImages]="reimbursementDetail.reimbursementImages" [noImageText]="isPaymentTerminal?'Actualized by Payment Terminal,Skip Receipt image':'Receipt Not Uploaded'">
                </c-receipt-image-viewer>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-flex space-between">
                  <span class="upload-type-title"> Receipts ({{ reimbursementImageCount }})</span>
                  <span *ngIf="reimbursementImageCount > 0">
                    <button *ngIf="reimbursementImageCount > 1" [disabled]="currentIndex == 0" mat-icon-button (click)="goToImage('prev')"><mat-icon>keyboard_arrow_left_outline</mat-icon></button>
                    <button *ngIf="reimbursementImageCount > 1" [disabled]="currentIndex == reimbursementImageCount - 1" mat-icon-button (click)="goToImage('next')">
                      <mat-icon>keyboard_arrow_right_outline</mat-icon>
                    </button>
                  </span>
                </div>
                <div class="d-flex">
                  <div class="uploader" *ngIf="canSave && isEdit"><reimbursement-image-upload [maxFile]="4" (onChangeImage)="onChangeImageCallback($event)"></reimbursement-image-upload></div>

                  <div class="image-list" *ngIf="reimbursementImageCount > 0">
                    <reimbursement-image-list [images]="reimbursementDetail.reimbursementImages" [imageViewerId]="imageViewerId" (clickDelete)="onDeleteImage($event)" [canDelete]="canSave && isEdit">
                    </reimbursement-image-list>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-5 col-xl-4">
            <div class="transaction-info-section">
              <div>
                <h3>{{ 'RECEIPT_TRANSACTIONS.LABEL.TRANSACTION_INFO' | translate }}({{ reimbursementDetail.transactionId }})</h3>
              </div>
              <ng-container *ngIf="isMallDollar">
                <div class="ocr-label-wrapper">
                  <span class="label" translate>Reimbursement ID</span>
                </div>
                <mat-form-field class="full-width">
                  <input matInput autocomplete="off" [value]="reimbursementDetail.transactionId" disabled />
                  <!-- <button mat-icon-button matSuffix class="sign-btn">
                    <mat-icon>launch</mat-icon>
                  </button> -->
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="!isMallDollar">
                <div class="ocr-label-wrapper">
                  <span class="label" translate>Actualization Txn .No</span>
                </div>
                <mat-form-field class="full-width" [isRemoveDeleteIcon]="true">
                  <input matInput autocomplete="off" [value]="reimbursementDetail.actualizationTxn" disabled />
                  <button mat-icon-button matSuffix class="sign-btn" (click)="navigateToRedemptionActivities()">
                    <mat-icon>launch</mat-icon>
                  </button>
                </mat-form-field>
              </ng-container>
              <div class="ocr-label-wrapper">
                <span class="label">{{ isMallDollar ? 'Conversion Date Time' : 'Actualization Date Time' }}</span>
              </div>
              <mat-form-field class="full-width">
                <input matInput autocomplete="off" [value]="reimbursementDetail.transactionDate | systemDateTimeFormat" disabled />
              </mat-form-field>

              <div class="ocr-label-wrapper">
                <span class="label" translate>Tenant</span>
              </div>
              <mat-form-field class="full-width">
                <input matInput autocomplete="off" [disabled]="true" [value]="reimbursementDetail.tenantId | merchant | async" />
              </mat-form-field>

              <!-- Tenant Account Name -->
              <div class="ocr-label-wrapper">
                <span class="label" translate>Tenant App Name</span>
              </div>
              <mat-form-field class="full-width">
                <input matInput autocomplete="off" [disabled]="true" [value]="reimbursementDetail.accountName || ''" />
              </mat-form-field>

              <div class="ocr-label-wrapper">
                <span class="label">{{isRPT?'RPT Transaction ID':'Invoice Number'}} <i *ngIf="businessUnit == 'CG' && duplicateFlag" class="invoiceNumber-Duplicated" (click)="onClickDuplicated()">Duplicated</i></span>
              </div>
              <mat-form-field class="full-width" style="word-wrap: break-word;">
                <input [hidden]="isRPT" matInput autocomplete="off" formControlName="invoiceNumber" />
                <a *ngIf="isRPT" (click)="changeTo(reimbursementDetail)" style="height: 18px;cursor: pointer;">{{reimbursementDetail?.rptTransactionId}}</a>
              </mat-form-field>
              <div class="ocr-label-wrapper">
                <span class="label">{{isRPT?'Transaction Amount':'Invoice Amount'}}</span>
              </div>
              <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
                <span>HKD&nbsp;</span>
                <input matInput autocomplete="off" [imask]="HKD_MASK" [unmask]="true" formControlName="spendingAmount" />
                <mat-error *ngIf="spendingAmount.hasError('invalidInput')">Invalid amount input</mat-error>
              </mat-form-field>
              <div class="ocr-label-wrapper">
                <span class="label">{{ isMallDollar ? 'Mall Dollar Amount' : 'eVoucher Total Amount' }}</span>
              </div>
              <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
                <span *ngIf="!isRequest">HKD&nbsp;</span>
                <input matInput autocomplete="off" [imask]="HKD_MASK" [unmask]="true" [value]="reimbursementDetail.transactionAmount | systemCurrency | replaceEmptyValue" disabled />
              </mat-form-field>
              <div class="ocr-label-wrapper">
                <span class="label" translate>Reimbursement amount</span>
              </div>
              <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
                <input matInput autocomplete="off" [value]="reimbursementDetail.reimbursementAmount | systemCurrency | replaceEmptyValue" disabled />
              </mat-form-field>
              <div class="ocr-label-wrapper">
                <span class="label" translate>Membership No.</span>
              </div>
              <mat-form-field class="full-width">
                <input matInput autocomplete="off" disabled [value]="reimbursementDetail.memberId" />
              </mat-form-field>

              <!-- redemption channel -->
              <div class="ocr-label-wrapper" [hidden]="!featureFlagService.getFeatureFlag('RPT')">
                <span class="label" translate>Redemption channel</span> 
              </div>
              <mat-form-field class="full-width" [hidden]="!featureFlagService.getFeatureFlag('RPT')">
                <input matInput autocomplete="off" disabled [value]="redemptionChannel? (redemptionChannel | genericCategory: 'REIMBURSEMENT_REDEMPTION.CHANNEL' | async) : '-'" />
              </mat-form-field>


              <div class="ocr-label-wrapper" [hidden]="!featureFlagService.getFeatureFlag('RPT')">
                <span class="label" translate>Actualization Channel</span> 
              </div>
              <mat-form-field class="full-width" [hidden]="!featureFlagService.getFeatureFlag('RPT')">
                <input matInput autocomplete="off" disabled [value]="reimbursementDetail.channel | genericCategory: 'ACTUALIZATION.CHANNEL' | async" />
              </mat-form-field>

              <div class="ocr-label-wrapper">
                <span class="label" translate>Last Updated By</span>
              </div>
              <mat-form-field class="full-width">
                <input matInput autocomplete="off" disabled [value]="reimbursementDetail.modifiedBy" />
              </mat-form-field>
              <hr />
              <ng-container *ngIf="!isMallDollar">
                <h3>E-Voucher Info</h3>
                <div class="ocr-label-wrapper">
                  <span class="label" translate>Serial Number</span>
                </div>

                <mat-form-field class="full-width ng-select-mat-form-field" [isRemoveDeleteIcon]="true">
                  <mat-select formControlName="serialNumber">
                    <mat-option *ngFor="let item of reimbursementDetail.evouchers" [value]="item.giftId">
                      {{ item.serialNumber }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="ocr-label-wrapper">
                  <span class="label" translate>Gift Name</span>
                </div>
                <mat-form-field class="full-width">
                  <input matInput autocomplete="off" [value]="selectedReimbursementEvoucher?.giftName | replaceEmptyValue" disabled />
                </mat-form-field>

                <div class="ocr-label-wrapper">
                  <span class="label" translate>Gift Value</span>
                </div>
                <mat-form-field class="full-width">
                  <input matInput autocomplete="off" [value]="selectedReimbursementEvoucher?.giftValue | systemCurrency | replaceEmptyValue" disabled />
                </mat-form-field>
                <div class="ocr-label-wrapper">
                  <span class="label" translate>Actual Reimbursement Amount</span>
                </div>
                <mat-form-field class="full-width">
                  <input matInput autocomplete="off" [value]="selectedReimbursementEvoucher?.actualReimbursementAmount | systemCurrency | replaceEmptyValue" disabled />
                </mat-form-field>
                <div class="ocr-label-wrapper">
                  <span class="label" translate>Promotion</span>
                </div>
                <mat-form-field class="full-width">
                  <input matInput autocomplete="off" [value]="selectedReimbursementEvoucher?.promotionName | replaceEmptyValue" disabled />
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="isMallDollar">
                <h3>Mall Dollar Info</h3>

                <div class="ocr-label-wrapper">
                  <span class="label" translate>By Base Rate</span>
                </div>

                <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
                  <input matInput autocomplete="off" [value]="reimbursementDetail.mallDollar.baseRate | systemCurrency" disabled />
                </mat-form-field>
                <div class="ocr-label-wrapper">
                  <span class="label" translate>By Promotion Rate</span>
                </div>
                <mat-form-field class="full-width flex-input calculation mat-hint-wrapper">
                  <input matInput autocomplete="off" [value]="reimbursementDetail.mallDollar.promotionRate | systemCurrency" disabled />
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="form-stick-bottom-bar view-transaction-stick-bottom-bar space-between" *ngIf="!isRequest">
      <div>
        <c-form-button label="history" [isLoading]="isSubmitting" [buttonType]="'stroked'" class="mg-left-10" (click)="onClickHistory()" icon="history" [upperCaseLabel]="true"></c-form-button>
      </div>
      <!-- <c-form-button [isLoading]="isSubmitting" buttonType="stroke" label="return to pending" [upperCaseLabel]="true" (click)="onClickReturn()"> </c-form-button> -->
      <div>
        <ng-container *ngIf="revertButtonShow">
          <c-form-button label="REVERT" *ngIf="isShowEditAndRevertBtn" [isLoading]="isSubmitting" [buttonType]="'stroked'" class="mg-left-10" (click)="revertButtonClick()" [upperCaseLabel]="true"></c-form-button>
        </ng-container>
        <c-form-button label="void" [isAny]="true" *ngIf="isShowVoidButton" [appCanAccess]="['ui#loyalty#reimbursement#transaction#void']" class="mg-left-10" (click)="voidButtonClick()" [upperCaseLabel]="true"></c-form-button>
        <ng-container *ngIf="canSave">
          <c-form-button label="edit" [upperCaseLabel]="true" *ngIf="!isEdit && isShowEditAndRevertBtn" (click)="onClickEdit()"></c-form-button>
          <c-form-button label="save" *ngIf="isEdit" [upperCaseLabel]="true" [isLoading]="isSubmitting" (click)="onClickSave()"></c-form-button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #duplicatedViewRef>
  <h3 class="dialog-title duplicated-dialog">
    Transactions Found ( {{ invoiceNumber?.value }} )
    <div class="close-button">
      <mat-icon (click)="closeDuplicated()">close</mat-icon>
    </div>
  </h3>
  <dx-data-grid #dataGrid appDatagridSort [dataSource]="invoiceNumberDataSource" [allowColumnResizing]="true" [columnAutoWidth]="true" class="reimbursementInvoice">
    <dxo-remote-operations [filtering]="false" [sorting]="true" [grouping]="false" [paging]="true" [summary]="false"> </dxo-remote-operations>
    <dxo-paging [pageSize]="32"></dxo-paging>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxi-column [width]="120" dataField="id" cellTemplate="transactionCell" caption="Transaction ID"> </dxi-column>
    <a (click)="changeReimbursement(data)" target="_blank" *dxTemplate="let data of 'transactionCell'" style="cursor: pointer">
      {{ data.value }}
    </a>
    <dxi-column [width]="200" dataField="transactionDate" cellTemplate="transactionDateCell" caption="Transaction Created Date"> </dxi-column>
    <div *dxTemplate="let data of 'transactionDateCell'">
      {{ data.value | systemDateTimeFormat }}
    </div>

    <dxi-column dataField="type" caption="Type" cellTemplate="typeCell"> </dxi-column>
    <div *dxTemplate="let data of 'typeCell'">
      {{ reimbursementTypeList[data.value]?.label }}
    </div>

    <dxo-load-panel [showPane]="true" [shading]="false" indicatorSrc="/assets/images/loading-circle.gif"> </dxo-load-panel>
  </dx-data-grid>
</ng-template>
