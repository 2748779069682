import { untilDestroyed } from '@core';
import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges, Output, EventEmitter, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ReceiptTransactionService, TransactionDialogService, DialogService, ChangeRequestService, UserService, StackViewerService, FormService, DropdownService, LoyaltyService, TenantService } from '@core/services';
import { GuestProfile, ReceiptTransaction, DialogData, AttachmentRequest, WorkflowRequestTransactions, ErrorMessageMapping, ErrorLabelMapping } from '@core/models';
import { ViewTransactionConstants } from '@app/pages/receipt-transaction/view-transaction/view-transaction.constants';
import { VoidTransactionDialogComponent } from '../dialogs/void-transaction-dialog/void-transaction-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { cloneDeep, isNumber } from 'lodash';
import { forkJoin, Observable, of, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineDateTime } from '@app/@core/utils/date-time/combine-date-time';
import { AttachmentsListComponent } from '../../attachments-list';
import { format, parseISO } from 'date-fns';
import { ReceiptTransactionConstant } from '@app/pages/receipt-transaction/receipt-transaction.constants';
import { TransactionConstants } from '../receipt-transaction.constant';
import { SystemCurrencyPipe } from '@app/@shared/pipes';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { DateMaskConstants, DateTimeMaskConstants } from '@shared/masks';
/**
 * Shared Transaction Detail Page used in transaction history
 */
@Component({
  selector: 'c-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
})
export class TransactionDetailComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() transactionId: string;
  @Input() isShowMemberInfo = true;
  @Input() isAllowSubmitVoidRequest = true;
  @Input() hasStickyThumbnail = false;
  @Input() hasWorkflowAuditSection = true;
  @Input() isShowEditButton = true;
  isEdit = false;
  isSubmitting = false;
  transactionInfoForm: UntypedFormGroup;
  ocrLabelVisibilityMapping = new Map();
  dropdownData: any = null;
  isInvoiceNumbersRequired = false;
  isRefRequired = false;

  ViewTransactionConstants = ViewTransactionConstants;
  @ViewChild('transactionButtonTemplate') transactionButtonTemplate: TemplateRef<any>;
  @ViewChild('workFlowAttachmentList') workFlowAttachmentList: AttachmentsListComponent;

  @Input('customData') customData: any; // For stack viewer
  @Output() updateTransactionStatus = new EventEmitter<ReceiptTransaction>();

  // Core Transaction Related
  transactionDetail: ReceiptTransaction;
  memberDetail: GuestProfile;
  associatedMemberDataSource: Array<any> = [];

  // Workflow Related
  attachments: AttachmentRequest[] = [];
  approvalHistoryList: WorkflowRequestTransactions[] = [];

  // Original Label Related
  isDisplayTransactionAmountOriginalLabel: boolean;
  isDisplayTransactionDateOriginalLabel: boolean;
  isDisplayTenantIdOriginalLabel: boolean;

  tenantList:any[] = [];

  errorMessageMapping: ErrorMessageMapping = {
    required: { 
      errorMsg: 'GENERAL.ERROR_MESSAGE.REQUIRED_FIELDS', showFieldList: true,
     },
  };

  errorLabelMapping: ErrorLabelMapping = {
    depositType: 'RECEIPT_TRANSACTIONS.LABEL.LINK_DEPOSIT_TRANSACTION.DEPOSIT_TYPE',
    paymentMethod: 'RECEIPT_TRANSACTIONS.LABEL.PAYMENT_METHOD',
    invoiceNumbers: 'RECEIPT_TRANSACTIONS.LABEL.INVOICE_NUMBER',
    rptAcquireReferenceNos: 'RECEIPT_TRANSACTIONS.LABEL.RPT_ACQUIRE_REF_NO'
  };
  getBusinessUnit = '';

  isRpt:boolean = false;
  allUsingPaymentTerminalId: any[] = [];
  pointNum: number = 0;
  placeholder: any = '';
  receiptTransactionAcquireReferenceNos: any[] = [];
  invoiceNumbersCache: any[] = []
  TIME_MASK = DateTimeMaskConstants.TIME;

  get isShowVoidReceiptSection(): boolean {
    return (
      this.transactionDetail &&
      this.transactionDetail.isVoidableTransaction &&
      (this.transactionDetail.status === ViewTransactionConstants.TRANSACTION_STATUS_APPROVED ||
        this.transactionDetail.status === ViewTransactionConstants.STATUS_PROCESSED ||
        this.transactionDetail.status === ViewTransactionConstants.STATUS_NON_ACTIVATED)
        && !TransactionConstants.notVoidTransactionType.includes(this.transactionDetail.type)
    );
  }

  get isShowEditReceiptSection(): boolean {
    return (
      (this.transactionDetail?.status === ViewTransactionConstants.TRANSACTION_STATUS_APPROVED || this.transactionDetail?.status === ViewTransactionConstants.STATUS_SUBMITTED) 
     &&
      !this.transactionDetail.withPendingForApprovalWorkflowRequest &&
      this.userService.hasPermission('ui#loyalty#transaction#modifyButtons')
    );
    // return (
    //   (this.transactionDetail?.status === ViewTransactionConstants.TRANSACTION_STATUS_APPROVED || this.transactionDetail?.status === ViewTransactionConstants.STATUS_SUBMITTED) && ( this.transactionDetail?.rptReceipt || (
    //     this.transactionDetail.verifyStatus !== ViewTransactionConstants.VERIFIED_WITHOUT_ISSUE &&
    //     this.transactionDetail.verifyStatus !== ViewTransactionConstants.VERIFIED_WITH_ISSUE 
    //   ))
    //  &&
    //   !this.transactionDetail.withPendingForApprovalWorkflowRequest &&
    //   this.userService.hasPermission('ui#loyalty#transaction#modifyButtons')
    // );
  }

  get isCurrentlyCreditToSelf() {
    if (this.transactionDetail.creditTo) {
      if (this.transactionDetail.creditTo === this.transactionDetail?.guestId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  get isDisableUploadBtn() {
    return this.transactionDetail && !this.userService.hasPermission('ui#loyalty#transaction#modifyButtons');
  }

  get remarks() {
    return this.transactionInfoForm.get('remarks');
  }

  get transactionDate() {
    return this.transactionInfoForm.get('transactionDate');
  }

  get transactionTime() {
    return this.transactionInfoForm.get('transactionTime');
  }

  get depositTransaction() {
    return this.transactionInfoForm.get('depositTransaction');
  }

  get depositType() {
    return this.transactionInfoForm.get('depositType');
  }

  get linkDepositTransactionId() {
    return this.transactionInfoForm.get('linkDepositTransactionId');
  }

  get isAllowAttachmentChange() {
    return this.isEdit && ![ViewTransactionConstants.VERIFIED_WITHOUT_ISSUE, ViewTransactionConstants.VERIFIED_WITH_ISSUE].includes(this.transactionDetail?.verifyStatus)
  }

  constructor(
    private receiptTransactionService: ReceiptTransactionService,
    private transactionDialogService: TransactionDialogService,
    private dialogService: DialogService,
    private changeRequestService: ChangeRequestService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private stackViewerService: StackViewerService,
    private formService: FormService,
    private dropdownService: DropdownService,
    public loyaltyService: LoyaltyService,
    private tenantService: TenantService,
    private systemCurrencyPipe: SystemCurrencyPipe,
    public featureFlagService: FeatureFlagService
  ) {
    this.getBusinessUnit = this.loyaltyService.currentProgramme.businessUnit;
    this.dropdownService
      .getDropdown()
      .pipe(take(1))
      .subscribe(
        (dropdown) => {
          this.dropdownData = dropdown;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  ngOnInit() {
    if (this.customData) {
      this.transactionId = this.customData.transactionId;
      this.isShowMemberInfo = this.customData.isShowMemberInfo;
      this.hasStickyThumbnail = this.customData.hasStickyThumbnail;
      this.hasWorkflowAuditSection = this.customData.hasWorkflowAuditSection;
      this.isAllowSubmitVoidRequest = this.customData.isAllowSubmitVoidRequest ?? true;
    }
    this.initTransactionForm();
    this.loadTransaction();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.transactionId) {
    //   this.loadTransaction();
    // }
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    // this.stackViewerService.addButtonTemplate(this.transactionButtonTemplate);
  }
  ocrPaymentMethodInit() {
    let paymentMethodList = this.dropdownData?.RECEIPT_TRANSACTION.PAYMENT_METHOD || [];
    console.log('333', paymentMethodList);
    paymentMethodList = paymentMethodList.filter((item: any) => {
      return item.value === this.transactionDetail.ocrPaymentMethod;
    });
    const payLabel = paymentMethodList.length > 0 ? paymentMethodList[0]?.label : '';
    this.ocrLabelVisibilityMapping.set('ocrPaymentMethodValue', payLabel);
    this.ocrLabelVisibilityMapping.set('paymentMethodGreenColor', this.transactionDetail.ocrPaymentMethod === this.transactionDetail.paymentMethod);
  }
  isNumberValid(val:any) {
    const num = Number(val);
    return isNumber(num) && !isNaN(num);
  }
  loadTransaction() {
    // forkJoin({
    //   tenantList: [],//this.tenantService.getTenantList(false, false),
    //   transactionDetail: this.receiptTransactionService.getTransactionDetailById(this.transactionId)
    // }).subscribe((res:any) => {
    this.receiptTransactionService.getTransactionDetailById(this.transactionId).subscribe((response:any) => {
      this.transactionDetail = response[0].transaction;
      this.invoiceNumbersCache = cloneDeep(this.transactionDetail?.invoiceNumbers) 
      this.tenantList = []//res.tenantList;
      if(this.transactionDetail?.rptAcquireReferenceNos) {
        this.receiptTransactionAcquireReferenceNos = this.transactionDetail?.rptAcquireReferenceNos.map((item:any)=> item.acquireReferenceNo);
       }

    this.isRpt = this.transactionDetail?.rptReceipt; //this.receiptTransactionService.isRptReceipt(this.transactionDetail, res.tenantList)

     
     if(this.isRpt && this.transactionDetail?.type == 'SPLIT_PAYMENT_TRANSACTION' ) {
          this.placeholder = 'N/A';
          this.transactionDetail.transactionAmount =`N/A`  as any;
     }

      // const rptExcludedAmount =  this.transactionDetail?.rptExcludedAmount;
      // const rptMallDollarDiscountedAmount = this.transactionDetail?.rptMallDollarDiscountedAmount;
      // const transactionAmount =this.transactionDetail?.transactionAmount; 
      // if(this.isNumberValid(transactionAmount) && this.isNumberValid(rptExcludedAmount) && this.isNumberValid(rptMallDollarDiscountedAmount) ) {
      //   this.pointNum = Number(transactionAmount) - Number(rptExcludedAmount) - Number(rptMallDollarDiscountedAmount)
      // }

      const checkOringinal = (transaction: ReceiptTransaction) => {
        if (transaction.receiptAmount !== this.transactionDetail.originalTransactionAmount && this.transactionDetail.originalTransactionAmount !== undefined) {
          this.isDisplayTransactionAmountOriginalLabel = true;
        } else {
          this.isDisplayTransactionAmountOriginalLabel = false;
        }

        if (moment(transaction.transactionDate).format('DD-MM-yyyy') !== moment(this.transactionDetail.originalTransactionDate).format('DD-MM-yyyy') && this.transactionDetail.originalTransactionDate !== undefined) {
          this.isDisplayTransactionDateOriginalLabel = true;
        } else {
          this.isDisplayTransactionDateOriginalLabel = false;
        }

        if (transaction.tenantId !== this.transactionDetail.originalTenantId && this.transactionDetail.originalTenantId !== undefined) {
          this.isDisplayTenantIdOriginalLabel = true;
        } else {
          this.isDisplayTenantIdOriginalLabel = false;
        }
      };
      checkOringinal(this.transactionDetail);
      this.updateTransactionStatus.emit(this.transactionDetail);
      this.memberDetail = response[0].memberDetail;
      const memberAssociatedMember = response[0].memberAssociatedMember;
      if (this.transactionDetail.workflowRequestTransactions) {
        this.approvalHistoryList = this.transactionDetail.workflowRequestTransactions.flat();
        this.attachments = this.changeRequestService.convertWorkflowAttachment(this.transactionDetail.receiptTransactionAttachments);
      }
      if (this.transactionDetail.withTransactionTime) {
        this.transactionDetail.transactionTime = this.transactionDetail.transactionDate;
      }
      // TODO: Dirty, to pipe
      const initAssociatedMemberDataSource = () => {
        this.associatedMemberDataSource = [
          {
            id: this.memberDetail.id,
            name: `Credit to Self (${this.memberDetail.firstName} ${this.memberDetail.lastName})`,
          },
        ];
        if (memberAssociatedMember) {
          this.associatedMemberDataSource.push({
            id: memberAssociatedMember.guestId,
            name: `Credit to Associated (${memberAssociatedMember.firstName} ${memberAssociatedMember.lastName})`,
          });
        }
      };
      initAssociatedMemberDataSource();
      const actualizedAmount = Number(this.transactionDetail?.rptDiscountedAmount) + Number(this.transactionDetail?.rptMallDollarDiscountedAmount)
      this.transactionInfoForm.patchValue(this.transactionDetail, { emitEvent: false });

      if (this.transactionDetail?.rptAcquireReferenceNos) {
        const receiptTransactionAcquireReferenceNos = this.transactionDetail?.rptAcquireReferenceNos.map((item: any) => item.acquireReferenceNo);
        this.transactionInfoForm.get('rptAcquireReferenceNos')?.setValue(receiptTransactionAcquireReferenceNos);
      }

      this.transactionInfoForm.get('actualizedAmount')?.setValue(actualizedAmount)

      if(this.isRpt && this.transactionDetail?.type == 'SPLIT_PAYMENT_TRANSACTION') {
        this.placeholder = 'N/A';
        this.transactionInfoForm.get('rptExcludedAmount')?.setValue(null)
        this.transactionInfoForm.get('actualizedAmount')?.setValue(null)
      }

      if (this.transactionDetail.linkDepositTransaction?.length) {
        this.linkDepositTransactionId.setValue(this.transactionDetail.linkDepositTransaction);
      }
      if (this.transactionDetail.transactionDate) {
        this.transactionInfoForm.get('transactionDate').setValue(moment(this.transactionDetail.transactionDate as string));
        if (this.transactionDetail.withTransactionTime) {
          this.transactionInfoForm.get('transactionTime').setValue(format(parseISO(this.transactionDetail.transactionDate as string), 'HH:mm'));
        }
      }
      this.initOcrLabelVisibilityMapping();
      this.onOcrFieldsUpdate('transactionDate');
      this.onOcrFieldsUpdate('transactionTime');
      this.onOcrFieldsUpdate('transactionAmount');
      this.onOcrFieldsUpdate('receiptAmount');
      this.onOcrFieldsUpdate('invoiceNumbers');
      this.onOcrFieldsUpdate('tenantId');
      this.ocrPaymentMethodInit();
      this.receiptTransactionService.receiptTransactionDetailChange(this.transactionDetail);
    });
  }

  /**
   * Void Receipt for Approved Transaction
   */
  onClickVoidReceipt() {
    const transactionDetail = {
      guestId: this.memberDetail.id,
      receiptTransactionId: this.transactionDetail.id,
    };
    this.transactionDialogService
      .openVoidTransactionDialog(transactionDetail, VoidTransactionDialogComponent)
      .pipe(untilDestroyed(this))
      .subscribe((dialogRef) => {
        dialogRef.afterClosed().subscribe((response: { isVoided: boolean }) => {
          if (response?.isVoided) {
            this.openSuccessfulDialog();
          }
        });
      });
  }

  onClickEditReceipt() {
    this.isEdit = true;
    this.remarks.enable();

    !this.transactionDetail.rptReceipt && this.transactionTime?.enable();
    
    if(this.transactionDetail.rptReceipt) {
      // this.transactionInfoForm.get('rptAcquireReferenceNos')?.enable();
      this.transactionInfoForm.get('paymentMethod')?.enable();
      this.transactionInfoForm.get('invoiceNumbers')?.enable();

      // this.transactionInfoForm.get('rptAcquireReferenceNos')?.setValidators(Validators.required);
      this.transactionInfoForm.get('paymentMethod')?.setValidators(Validators.required);
      this.transactionInfoForm.get('invoiceNumbers')?.setValidators(Validators.required);

      // this.transactionInfoForm.get('rptAcquireReferenceNos')?.updateValueAndValidity({ emitEvent: false });
      this.transactionInfoForm.get('paymentMethod')?.updateValueAndValidity({ emitEvent: false });
      this.transactionInfoForm.get('invoiceNumbers')?.updateValueAndValidity({ emitEvent: false });

      this.isInvoiceNumbersRequired = true;
      // this.isRefRequired = true;
    }
    if (this.transactionDetail.status === TransactionConstants.STATUS_SUBMITTED) {
      if (this.transactionDetail.receiptAmount < 0 || this.transactionDetail.transactionAmount < 0) {
        const dialogData: DialogData = {
          content: 'Please edit pick up slip details in transaction audit portal.',
          yesLabel: 'COMMON.LABEL.CONFIRM',
          yesCallback: (dialogRef) => {
            this.router.navigate([`admin/receipt-transaction/view/${this.transactionDetail.id}`]);
            dialogRef.close();
          },
          noLabel: 'Cancel',
          noCallback: (dialogRef) => {
            this.isEdit = false;
            this.remarks.disable();
            dialogRef.close();
          },
          hasBackdrop: true,
        };

        this.dialogService.showConfirmationDialog(dialogData).pipe(untilDestroyed(this)).subscribe();
        return;
      } else {
        this.depositTransaction.enable({ emitEvent: false });
        this.depositType.enable();
        if (!this.depositTransaction.value) this.linkDepositTransactionId.enable();
      }
    }
  }

  onClickCancelEditReceipt() {
    if (this.transactionDetail.status === TransactionConstants.STATUS_SUBMITTED) {
      this.depositTransaction.patchValue(this.transactionDetail.depositTransaction, { emitEvent: false });
      this.depositType.patchValue(this.transactionDetail.depositType, { emitEvent: false });
      if (this.transactionDetail.linkDepositTransaction) {
        this.linkDepositTransactionId.patchValue(this.transactionDetail.linkDepositTransaction, { emitEvent: false });
      }

      this.depositType.markAsPristine();
      this.linkDepositTransactionId.markAsPristine();
      this.depositTransaction.markAsPristine();

      this.depositTransaction.disable({ emitEvent: false });
      this.depositType.disable();
      this.linkDepositTransactionId.disable();
    }
    this.isEdit = false;
    this.remarks.patchValue(this.transactionDetail.remarks);

    this.remarks.markAsPristine();

    this.remarks.disable();

    if(!this.transactionDetail.rptReceipt) {
      this.transactionTime?.disable();
      const dateTime = format(parseISO(this.transactionDetail?.transactionTime as string), 'HH:mm')
      this.transactionTime?.setValue(dateTime)
    }

    if(this.transactionDetail.rptReceipt) {
      this.isInvoiceNumbersRequired = false;
      
      this.transactionInfoForm.get('paymentMethod')?.setValue(this.transactionDetail.paymentMethod)
      const invoiceNumbersCache = cloneDeep(this.invoiceNumbersCache) 
      this.transactionInfoForm.get('invoiceNumbers')?.setValue(invoiceNumbersCache)
      this.transactionInfoForm.get('paymentMethod')?.disable();
      this.transactionInfoForm.get('invoiceNumbers')?.disable();
      // const receiptTransactionAcquireReferenceNos = cloneDeep(this.receiptTransactionAcquireReferenceNos)
      // this.transactionInfoForm.get('rptAcquireReferenceNos')?.setValue(receiptTransactionAcquireReferenceNos)
    }
    this.workFlowAttachmentList.resetFileList();
  }

  onClickSaveReceipt() {
    if (this.transactionInfoForm.invalid) {
      const formErrors = this.formService.getFormError(this.transactionInfoForm, this.errorMessageMapping, this.errorLabelMapping);
      const dialogData: DialogData = {
        title: 'Error',
        errorList: formErrors,
        width: '370px',
        yesCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showErrorDialog(dialogData).subscribe();
    } else {
      this.preTransformData();
      const dialogData: DialogData = {
        content: 'RECEIPT_TRANSACTIONS.MESSAGE.CONFIRM_SAVE',
        yesLabel: 'CONFIRM',
        yesCallback: (dialogRef) => {
          this.saveTransaction();
          dialogRef.close();
        },
        noLabel: 'CANCEL',
        noCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showConfirmationDialog(dialogData).subscribe();
    }
  }

  attachmentOnChange(e: any[]) {
    this.attachments = cloneDeep(e);
  }

  private openSuccessfulDialog(type: string = 'VOID_RECEIPT') {
    let dialogData: DialogData = {
      content: `Request Submitted`,
      buttonOneLabel: 'OK',
      buttonOneCallback: (successfulDialogRef) => {
        successfulDialogRef.close();
      },
    };
    if (type === 'EDIT_TRANSACTION') {
      dialogData = {
        content: `Transaction Edited`,
        buttonOneLabel: 'OK',
        buttonOneCallback: (successfulDialogRef) => {
          successfulDialogRef.close();
        },
      };
    }
    this.dialogService.showSuccessDialog(dialogData).subscribe(() => {
      this.reloadCurrentRoute();
    });
  }

  private reloadCurrentRoute() {
    this.stackViewerService.reloadViewer();
  }

  private preTransformData() {
    if (this.transactionInfoForm.get('transactionTime').value) {
      const transactionDate = this.transactionDate.value;
      const transactionDateTime = moment(transactionDate).set({ hour: this.transactionTime.value.slice(0, 2), minute: this.transactionTime.value.slice(3, 5), second: 0, millisecond: 0 });
      this.transactionDate.setValue(transactionDateTime);
    }
  }

  private uploadWorkflowAttachments(): Observable<AttachmentRequest[]> {
    if (this.attachments.length > 0) {
      const fileHandlingObservable: Observable<any>[] = [];
      this.attachments.forEach((attachment: any) => {
        fileHandlingObservable.push(this.changeRequestService.uploadAttachment(attachment.file, null, null, true));
      });
      return zip(...fileHandlingObservable).pipe(
        map((response: any[]) => {
          return response;
        })
      );
    } else {
      return of(null);
    }
  }

  private initTransactionForm() {
    this.transactionInfoForm = this.fb.group({
      businessUnit: [],
      createdBy: [],
      createdDate: [],
      creditTo: [null],
      duplicateInvoiceOverride: [false],
      electronicReceipts: [],
      furtherApprovalReasons: [null],
      furtherApprovalRemarks: [null],
      guestId: [],
      id: [],
      invoiceNumbers: [{ value: [], disabled: true }],
      rptAcquireReferenceNos: [{ value: [], disabled: true }],
      loyaltyProgram: [],
      memberIdentity: [],
      memberStatus: [],
      membershipTier: [],
      modifiedBy: [],
      depositTransaction: [{ value: null, disabled: true }],
      modifiedDate: [],
      transactionModifyReason: [null],
      paymentMethod: [{ value: null, disabled: true }],
      pointTransactionLogic: [],
      receiptAmount: [''],
      rejectedReason: [],
      rejectedRemarks: [],
      remarks: [{ value: null, disabled: true }],
      salesReceipts: [],
      status: [],
      tenantId: [''],
      tenantName: [''],
      transactionAmount: [],
      transactionDate: [''],
      transactionTime: [{value: '', disabled: true }],
      transactionUploadSource: [],
      updatedByDataEntryTeam: [],
      version: [],
      depositType: [{ value: null, disabled: true }],
      linkDepositTransactionId: [{ value: null, disabled: true }],
      rptExcludedAmount: [{ value: null, disabled: true }],
      actualizedAmount: [{ value: null, disabled: true }],
    });
    if (this.haveDepositTransactionAccess) {
      this.depositTransaction.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
        if (value) {
          if (this.linkDepositTransactionId.value?.length > 0) {
            const dialogData: DialogData = {
              content: 'Are you sure to select such option? All linked deposit transaction will be lost.',
              yesLabel: 'COMMON.LABEL.CONFIRM',
              yesCallback: (dialogRef) => {
                this.depositType.enable();
                this.depositType.setValidators(Validators.required);
                this.depositType.updateValueAndValidity();
                this.linkDepositTransactionId.setValue(null, { emitEvent: false });
                this.linkDepositTransactionId.disable();
                dialogRef.close();
              },
              noLabel: 'Cancel',
              noCallback: (dialogRef) => {
                dialogRef.close();
                this.depositTransaction.setValue(false, { emitEvent: false });
              },
              hasBackdrop: true,
            };

            this.dialogService.showConfirmationDialog(dialogData).pipe(untilDestroyed(this)).subscribe();
          } else {
            this.depositType.enable();
            this.depositType.setValidators(Validators.required);
            this.depositType.updateValueAndValidity();
            this.linkDepositTransactionId.setValue(null, { emitEvent: false });
            this.linkDepositTransactionId.disable();
          }
        } else {
          this.depositType.setValidators(null);
          this.depositType.setValue(null, { emitEvent: false });
          this.depositType.updateValueAndValidity();
          this.depositType.disable();
          this.linkDepositTransactionId.enable();
        }
      });
    }
  }

  get haveDepositTransactionAccess(): boolean {
    return this.userService.hasPermission('ui#loyalty#transaction#depositTransaction');
  }

  private async saveTransaction() {
    // Prepare attachment
    this.isSubmitting = true;
    this.uploadWorkflowAttachments().subscribe((attachmentList) => {
      const depositRelatedBodyParams = {
        depositType: this.transactionInfoForm.get('depositType').value,
        pickUpSlip: this.linkDepositTransactionId.value?.length > 0,
        depositSlip: this.depositTransaction.value,
        linkDepositTransactionId:
          this.linkDepositTransactionId.value?.length > 0
            ? this.linkDepositTransactionId.value.map((transaction: any) => {
                return transaction.id;
              })
            : [],
      };
      let requestBody = {
        id: this.transactionDetail.id,
        invoiceNumbers: this.transactionInfoForm.get('invoiceNumbers').value,
        paymentMethod: this.transactionInfoForm.get('paymentMethod').value,
        receiptAmount: this.transactionInfoForm.get('receiptAmount').value && this.transactionInfoForm.get('receiptAmount').value !== '' ? +this.transactionInfoForm.get('receiptAmount').value : null,
        tenantId: this.transactionInfoForm.get('tenantId').value,
        transactionAmount: this.transactionInfoForm.get('transactionAmount').value && this.transactionInfoForm.get('transactionAmount').value !== '' ? +this.transactionInfoForm.get('transactionAmount').value : null,
        transactionDate: combineDateTime(this.transactionInfoForm.get('transactionDate').value, this.transactionInfoForm.get('transactionTime').value),
        withTransactionTime: this.transactionInfoForm.get('transactionTime').value && this.transactionInfoForm.get('transactionTime').value.trim() !== '' ? true : false,
        attachments: cloneDeep(this.attachments),
        version: this.transactionDetail.version,
        remarks: this.transactionInfoForm.get('remarks').value,
        creditTo: this.transactionInfoForm.get('creditTo').value,
        depositTransaction: this.transactionInfoForm.get('depositTransaction').value,
        depositType: this.transactionInfoForm.get('depositType').value,

        rptExcludedAmount: this.transactionInfoForm.get('rptExcludedAmount')?.value,
        rptMallDollarDiscountedAmount: this.transactionDetail.rptMallDollarDiscountedAmount,
        rptAcquireReferenceNos: this.transactionInfoForm.get('rptAcquireReferenceNos')?.value,
      };

      if (this.haveDepositTransactionAccess) {
        requestBody = { ...requestBody, ...depositRelatedBodyParams };
      }

      if (attachmentList?.length > 0) {
        requestBody.attachments = requestBody.attachments.map((attachment: any, index: number) => {
          attachment.filePath = attachmentList[index].filePath;
          delete attachment.file;
          return attachment;
        });
      }

      this.receiptTransactionService.updateTransaction(requestBody).subscribe(
        () => {
          this.openSuccessfulDialog('EDIT_TRANSACTION');
        },
        (err) => {
          this.isSubmitting = false;
        }
      );
    });
  }

  initOcrLabelVisibilityMapping(): void {
    this.ocrLabelVisibilityMapping.set('ocrSalesReceiptTransactionDate', false);
    this.ocrLabelVisibilityMapping.set('ocrTransactionTime', false);
    this.ocrLabelVisibilityMapping.set('ocrSalesReceiptAmount', false);
    this.ocrLabelVisibilityMapping.set('ocrElectronicReceiptAmount', false);
    this.ocrLabelVisibilityMapping.set('ocrInvoiceNumber', false);
    this.ocrLabelVisibilityMapping.set('ocrTenantId', false);
  }

  onOcrFieldsUpdate(fieldName: string) {
    switch (fieldName) {
      case 'transactionDate': {
        const inputValue = moment(this.transactionInfoForm.get('transactionDate').value).format('YYYY-MM-DD');
        if (!this.transactionDetail.ocrSalesReceiptTransactionDate) {
          this.ocrLabelVisibilityMapping.set('ocrSalesReceiptTransactionDate', false);
        } else {
          const ocrValue = moment(this.transactionDetail.ocrSalesReceiptTransactionDate).format('YYYY-MM-DD');
          this.ocrLabelVisibilityMapping.set('ocrSalesReceiptTransactionDate', ocrValue);
          this.ocrLabelVisibilityMapping.set('ocrSalesReceiptTransactionDateGreenColor', inputValue === ocrValue);
        }

        if (this.transactionDetail.ocrElectronicReceiptTransactionDate) {
          const ocrElectronicValue = moment(this.transactionDetail.ocrElectronicReceiptTransactionDate).format('YYYY-MM-DD');
          this.ocrLabelVisibilityMapping.set('ocrElectronicTransactionDateGreenColor', inputValue === ocrElectronicValue);
        } else {
          this.ocrLabelVisibilityMapping.set('ocrElectronicTransactionDateGreenColor', false);
        }
        break;
      }
      case 'transactionTime': {
        const inputValue = this.transactionInfoForm.get('transactionTime').value;
        if (!this.transactionDetail.ocrSalesReceiptTransactionDate) {
          this.ocrLabelVisibilityMapping.set('ocrTransactionTime', false);
        } else {
          const ocrValue = moment(this.transactionDetail.ocrSalesReceiptTransactionDate).format('HH:mm');
          this.ocrLabelVisibilityMapping.set('ocrTransactionTime', ocrValue);
          this.ocrLabelVisibilityMapping.set('ocrTransactionTimeGreenColor', inputValue === ocrValue);
        }
        if (this.transactionDetail.ocrElectronicReceiptTransactionDate) {
          const ocrElectronicValue = moment(this.transactionDetail.ocrElectronicReceiptTransactionDate).format('HH:mm');
          this.ocrLabelVisibilityMapping.set('ocrElectronicTimeGreenColor', inputValue === ocrElectronicValue);
        } else {
          this.ocrLabelVisibilityMapping.set('ocrElectronicTimeGreenColor', false);
        }
        break;
      }
      case 'transactionAmount':
        if (!this.transactionDetail.ocrSalesReceiptAmount) {
          this.ocrLabelVisibilityMapping.set('ocrSalesReceiptAmount', false);
        } else {
          const inputValue = parseFloat(this.transactionInfoForm.get('transactionAmount').value);
          const ocrValue = parseFloat(this.transactionDetail.ocrSalesReceiptAmount);
          this.ocrLabelVisibilityMapping.set('ocrSalesReceiptAmountGreenColor', inputValue === ocrValue);
          this.ocrLabelVisibilityMapping.set('ocrSalesReceiptAmount', true);
        }
        break;
      case 'receiptAmount':
        if (!this.transactionDetail.ocrElectronicReceiptAmount) {
          this.ocrLabelVisibilityMapping.set('ocrElectronicReceiptAmount', false);
        } else {
          // const inputValue = parseFloat(this.transactionInfoForm.get('receiptAmount').value);
          // const ocrValue = parseFloat(this.transactionDetail.ocrElectronicReceiptAmount);
          // this.ocrLabelVisibilityMapping.set('ocrElectronicReceiptAmount', inputValue !== ocrValue);
          this.ocrLabelVisibilityMapping.set('ocrElectronicReceiptAmount', true);
        }
        break;
      case 'invoiceNumbers':
        if (!this.transactionDetail.ocrInvoiceNumber) {
          this.ocrLabelVisibilityMapping.set('ocrInvoiceNumber', false);
        } else {
          const inputValues: string[] = this.transactionInfoForm.get('invoiceNumbers').value;
          const ocrValue = this.transactionDetail.ocrInvoiceNumber;
          const isExactMatchArray = inputValues.length === ocrValue.length && inputValues.every((value, index) => value === ocrValue[index]);
          // this.ocrLabelVisibilityMapping.set('ocrInvoiceNumber', !inputValues || !isExactMatchArray);
          this.ocrLabelVisibilityMapping.set('ocrInvoiceNumber', ocrValue);
        }
        break;
      case 'tenantId':
        const inputValue = this.transactionInfoForm.get('tenantId')?.value;
        const ocrValue = this.transactionDetail.ocrTenantId;
        this.ocrLabelVisibilityMapping.set('ocrTenantId', inputValue !== ocrValue);

        // if (!this.transactionDetail.ocrTenantId) {
        //   this.ocrLabelVisibilityMapping.set('ocrTenantId', false);
        // } else {
        //   const inputValue = this.transactionInfoForm.get('tenantId').value;
        //   const ocrValue = this.transactionDetail.ocrTenantId;
        //   this.ocrLabelVisibilityMapping.set('ocrTenantId', inputValue !== ocrValue);
        // }
        break;
      case 'paymentMethod':
        this.ocrLabelVisibilityMapping.set('paymentMethodGreenColor', this.transactionDetail.ocrPaymentMethod === this.transactionInfoForm.get('paymentMethod')?.value);
        break;
    }
  }
  get isOCRFuzzy(): boolean {
    return this.transactionDetail.ocrInvoiceNumberFuzzyMatch;
  }

  get ocrReceiptAmountFuzzyMatch(): boolean {
    return this.transactionDetail.ocrReceiptAmountFuzzyMatch;
  }
}
