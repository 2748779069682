<h2 mat-dialog-title class="error-title">{{ data.title || 'Error' | translate }}</h2>
<div mat-dialog-content>
  <ul *ngIf="data.errorList">
    <li *ngFor="let errorMsg of Object.keys(data.errorList)">
      {{ errorMsg | translate }}
      <ng-container *ngIf="Array.isArray(data.errorList[errorMsg])">
        <ul>
          <li *ngFor="let subErrMsg of data.errorList[errorMsg]">
            <ng-container *ngIf="Array.isArray(subErrMsg); else stringTmpl">
              <span *ngFor="let subErrMsgFragment of subErrMsg; let last = last">
                {{ subErrMsgFragment | translate }}
                <ng-container *ngIf="!last"> -> </ng-container>
              </span>
            </ng-container>
            <ng-template #stringTmpl>
              {{ subErrMsg | translate }}
            </ng-template>
          </li>
        </ul>
      </ng-container>
    </li>
  </ul>
  <p *ngIf="data.content && !data?.malldollarInfo" translate>
    {{ data.content }}
  </p>
  <div *ngIf="data?.malldollarInfo">
   {{ data.content }} <a target="_blank" [routerLink]="['admin/reimbursement/view/',data?.malldollarInfo?.reimbursementSummaryID]">{{data?.malldollarInfo?.guestId}}</a>
  </div>
</div>
<p *ngIf="data.hasDebugMessage" translate class="extra-details">
  <span *ngIf="customData?.dateTime">Date: {{ customData.dateTime }}</span
  ><br />
  <span *ngIf="customData?.traceId">Trace ID: {{ customData.traceId }}</span>
</p>
<div mat-dialog-actions class="action-wrapper error-action-wrapper">
  <button class="label-uppercase" mat-stroked-button color="warn" (click)="data.yesCallback(dialogRef)">{{ data.yesLabel || 'CONFIRM' }}</button>
</div>
