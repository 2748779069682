import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, HostBinding, Input, ElementRef, Inject, Optional, Self, SimpleChanges, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { MatChipInputEvent, MatChipListbox } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return true;
  }
}

@Component({
  selector: 'app-chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatFormFieldControl, useExisting: ChipInputComponent }],
})
export class ChipInputComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<any[]> {
  @ViewChild('chipList') chipListCmp: MatChipListbox;
  static nextId = 0;
  matcher = new MyErrorStateMatcher();

  @Input() readonly = false;
  @Input() fieldLabel: string;
  @Input() isForbidChinese = false;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return this.value ? this.value.length === 0 : true;
  }

  get errorState() {
    return this.ngControl !== null && this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): string[] {
    return this._value || [];
  }
  set value(options: string[]) {
    this._value = options || [];
    this.stateChanges.next();
    this.ref.detectChanges();
  }

  public touched: boolean;

  stateChanges = new Subject<void>();
  @HostBinding() id = `chip-input-${ChipInputComponent.nextId++}`;

  focused = false;
  // errorState: boolean;

  public _value: string[];
  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  // chip input specific config
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public selectable = true;
  public removable = true;
  public addOnBlur = true;

  public chipList: string[] = [];

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    private ref: ChangeDetectorRef
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin && !this.disabled && !this.readonly;
      this.stateChanges.next();
    });
  }

  setDescribedByIds(ids: string[]): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.stateChanges.next();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onContainerClick(event: MouseEvent): void {
    this.onTouched();
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.ref.detectChanges();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (delta: any) => {};

  onTouched = () => {
    this.touched = true;
  };

  ngOnInit() {}

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  add(event: MatChipInputEvent) {
    if (event.value && event.value !== '' && this.value.indexOf(event.value) === -1) {
      this.value.push(event.value.trim());
      event.input.value = '';

      this.onChange(this.value);
      this.stateChanges.next();
    }
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const chineseRegex = /[,，\u4e00-\u9fa5]/g;
    if (this.isForbidChinese && chineseRegex.test(input.value)) {
      input.value = input.value.replace(chineseRegex, '');
      event.preventDefault();
    }
  }


  remove(value: string) {
    const index = this.value.indexOf(value);
    if (index >= 0) {
      this.value.splice(index, 1);
    }

    this.onChange(this.value);
    this.stateChanges.next();
  }
}
