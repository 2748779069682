import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LoyaltyService } from '@core';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private loyaltyService: LoyaltyService) {}
  
  // 确认是显示PMP or PMS
  get moduleName(): string {
    const bu = this.loyaltyService?.currentProgramme?.businessUnit;
    return environment.FEATURE_FLAGS['PMP']?.[bu] ? 'PMP': 'PMS'
  }
 
  getFeatureFlag(featureName: string): boolean {
    let bu = this.loyaltyService.currentProgramme?.businessUnit;
    if (!environment.FEATURE_FLAGS.hasOwnProperty(featureName)) return false;
    if (!environment.FEATURE_FLAGS[featureName].hasOwnProperty(bu)) return false;
    return environment.FEATURE_FLAGS[featureName][bu];
  }
}
