export class ReimbursementConstants {
  public static STATUS_PENDING = 'PENDING';
  public static STATUS_SUBMITTED = 'SUBMITTED';
  public static STATUS_APPROVED = 'APPROVED';
  public static STATUS_REJECTED = 'REJECTED';
  public static STATUS_PENDING_APPROVAL = 'PENDING_APPROVAL';
  public static STATUS_EXPIRED = 'EXPIRED';
  public static STATUS_ENDORSED = 'ENDORSED';
  public static STATUS_VOIDED = 'VOIDED';
  public static STATUS_REMOVED = 'REMOVED';
  public static STATUS_PFE = 'PENDING_ENDORSEMENT';
  public static STATUS_REVERTED = 'REVERTED';
  public static PMS_STATUS_NOT_INTEGRATED = 'NOT_INTEGRATED';
  public static PMS_STATUS_INTEGRATED = 'INTEGRATED';
  public static PMS_STATUS_ERROR = 'ERROR';
  public static PMS_STATUS_PMS_CANCELLED = 'PMS_CANCELLED';
  public static EAI_CREATE_PO_ERROR = 'EAI_CREATE_PO_ERROR';
  public static TRANSACTION_LIST_TYPE = {
    EVOUCHER: 'E_VOUCHER',
    MALL_DOLLAR: 'MALL_DOLLAR',
  };
  public static TRANSACTION_TYPE_LIST = [
    {
      label: 'E-voucher',
      value: 'E_VOUCHER',
    },
    { label: 'Mall Dollar', value: 'MALL_DOLLAR' },
  ];
  public static MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public static RE_SYNC_TO_PMS_PERMISSION = 'ui#loyalty#reimbursement#pms#resync';
  public static PMP_PERMISSION = 'ui#loyalty#reimbursement#pmp#poCreation'
  public static REIMBURSEMENT_PMS_STATUS = [
    { label: 'Approved', value: 'PMS_APPROVED' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Rejected', value: 'PMS_REJECTED' },
    { label: 'Closed', value: 'PMS_CLOSED' },
    { label: 'Revised', value: 'PMS_REVISED' },
    { label: 'Draft', value: 'PMS_DRAFT' },
    { label: 'Waiting', value: 'PMS_WAITING' },
    { label: 'Cancelled', value: 'PMS_CANCELLED' },
    { label: 'Voided', value: 'PMS_VOIDED' }
  ];
}
