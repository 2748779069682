import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'c-form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.scss'],
  styles: [
    `
          button,
          span {
            pointer-events: auto;
          }
          :host ::ng-deep {
            pointer-events: none;
          }
          :host ::ng-deep button * {
            pointer-events: none;
          }
        `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Input() public isDisabled = false;
  @Input() buttonType: 'stroked' | 'flat' = 'flat';
  @Input() htmlButtonType = 'submit';
  @Input() routerLink: RouterLink;

  buttonLabel: string;
  buttonIcon: string;
  style: any;
  classList: any;
  buttonWidth: number;
  isUpperCase = true;
  isButtonDisabled: boolean;
  isSubmitting = false;
  buttonColor: ThemePalette = 'primary';

  constructor() {}

  ngOnInit(): void {}

  @Input() set label(label: string) {
    this.buttonLabel = label;
  }

  @Input() set icon(iconClass: string) {
    this.buttonIcon = iconClass;
  }

  @Input() set customStyle(value: { [klass: string]: any } | null) {
    this.style = value;
  }

  @Input() set class(value: string | string[] | Set<string> | { [klass: string]: any }) {
    this.classList = value;
  }

  @Input() set color(color: ThemePalette) {
    this.buttonColor = color;
  }

  @Input() set width(width: number) {
    this.buttonWidth = width;
  }

  @Input() set upperCaseLabel(isUpperCase: boolean) {
    this.isUpperCase = isUpperCase;
  }

  @Input() set disabled(isDisable: boolean) {
    this.isButtonDisabled = isDisable;
  }

  @Input() set isLoading(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }
}
