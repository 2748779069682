/*
 * Permission resources by module
 * Naming Conversion Format : 'ui#{service}#{module name}#{page/dialog/button/function name}'
 */
export class PermissionResources {
  /** 1. Campaign Module */

  static LOYALTY_CAMPAIGN_LIST_PAGE = 'ui#loyalty#campaign#listPage';
  static LOYALTY_CAMPAIGN_EDIT_PAGE = 'ui#loyalty#campaign#editPage';

  /** 2. Member Module */

  static LOYALTY_MEMBER_CREATE_MEMBER_PAGE = 'ui#loyalty#guest#createPage';
  static LOYALTY_MEMBER_LIST_MEMBER_PAGE = 'ui#loyalty#guest#listPage';
  static LOYALTY_MEMBER_VIEW_MEMBER_PAGE = 'ui#loyalty#guest#viewPage';
  static LOYALTY_MEMBER_EDIT_MEMBER_PAGE = 'ui#loyalty#guest#editPage';
  static LOYALTY_MEMBER_INVITATION_LINK_PAGE = 'ui#loyalty#guest#invitationLinkPage';
  static LOYALTY_MEMBER_CS_UPLOAD_RECEIPT_PAGE = 'ui#loyalty#guest#csUploadReceiptPage';
  static LOYALTY_MEMBER_GE_UPLOAD_RECEIPT_PAGE = 'ui#loyalty#guest#geUploadReceiptPage';

  /** 3. Receipt Transaction Module */

  static LOYALTY_TRANSACTION_LIST_PAGE = 'ui#loyalty#transaction#listPage';
  static LOYALTY_TRANSACTION_EDIT_PAGE = 'ui#loyalty#transaction#editPage';
  static LOYALTY_TRANSACTION_SAVE_AND_APPROVE = 'loyalty#transaction#saveAndApprove';
  static LOYALTY_TRANSACTION_CS_SAVE_AND_APPROVE = 'loyalty#transaction#csSaveAndApprove';
  static LOYALTY_TRANSACTION_GE_SAVE_AND_APPROVE = 'loyalty#transaction#geSaveAndApprove';
  static LOYALTY_RECEIPT_SPOT_CHECK = 'ui#loyalty#transaction#spotCheckList';

  /** 4. Gift Module */

  static LOYALTY_GIFT_LIST_PAGE = 'ui#loyalty#campaign#gift#listPage';
  static LOYALTY_GIFT_EDIT_PAGE = 'ui#loyalty#campaign#gift#editPage';

  /** 5. Report Module */

  static LOYALTY_REPORT_LIST_PAGE = 'ui#loyalty#report#report#listPage';

  /** 6. Workflow Module */

  static LOYALTY_WORKFLOW_REQUEST_INBOX_PAGE = 'ui#loyalty#guest#workflow#workflowRequest#inboxPage';
  static LOYALTY_WORKFLOW_REQUEST_DETAIL_PAGE = 'ui#loyalty#guest#workflow#workflowRequest#detailPage';

  /** 7. Reimbursement Module */

  static LOYALTY_REIMBURSEMENT_LIST_PAGE = 'ui#loyalty#reimbursement#viewPage';
  static LOYALTY_REIMBURSEMENT_VIEW_SUMMARY_PAGE = 'ui#loyalty#reimbursement#summary#viewPage';
  static LOYALTY_REIMBURSEMENT_VIEW_TRANSACTION_PAGE = 'ui#loyalty#reimbursement#transaction#viewPage';
  static LOYALTY_REIMBURSEMENT_EDIT_TRANSACTION_PAGE = 'ui#loyalty#reimbursement#transaction#editPage';

  /** -- Uncategorized */
  static LOYALTY_CURRENCY_CONVERSION_RATE_LIST_PAGE = 'ui#loyalty#admin#currencyConversionRate#listPage';
  static LOYALTY_EDIT_CURRENCY_CONVERSION_RATE = 'ui#loyalty#admin#currencyConversionRate#editPage';

  static LOYALTY_PROGRAMME_OVERVIEW = 'ui#loyalty#tier#overviewPage';
  static LOYALTY_VIEW_TIER = 'ui#loyalty#tier#tierAndTierRulePage';
  static LOYALTY_EDIT_TIER = 'ui#loyalty#tier#editTierPage';
  static LOYALTY_CREATE_TIER = 'ui#loyalty#tier#createTierPage';

  static LOYALTY_PROMOTION_LIST_PAGE = 'ui#loyalty#campaign#promotion#listPage';
  static LOYALTY_PROMOTION_EDIT_PAGE = 'ui#loyalty#campaign#promotion#editPage';

  static LOYALTY_TENANT_LIST_PAGE = 'ui#loyalty#admin#tenant#listPage';
  static LOYALTY_TENANT_EDIT_PAGE = 'ui#loyalty#admin#tenant#editPage';

  static LOYALTY_PARTNER_LIST_PAGE = 'ui#loyalty#admin#partner#listPage';
  static LOYALTY_PARTNER_EDIT_PAGE = 'ui#loyalty#admin#partner#editPage';

  static LOYALTY_PROGRAMME_LIST_PAGE = 'ui#loyalty#admin#loyaltyProgram#listPage';
  static LOYALTY_PROGRAMME_EDIT_PAGE = 'ui#loyalty#admin#loyaltyProgram#editPage';

  static LOYALTY_WHITE_LIST_PAGE = 'ui#loyalty#admin#workflowWhiteList#listPage';
  static LOYALTY_ACCOUNT_PAGE = 'ui#loyalty#reimbursement#accountcode#list';

  /** -- OTP */
  static OTP_VIEW_PAGE = 'ui#otp#otp#viewPage';

  static REDEMPTION_VIEW_PAGE = 'ui#loyalty#redemptionActivities#list';

  /** -- Approval Matrix Review */
  static APPROVAL_MATRIX_REVIEW = 'ui#approvalMatrix#approvalMatrix#viewPage';
}
