<app-html-tooltip icon="error" minWidth="280">
  <ng-container *ngIf="tooltipMsg">
    <div>{{ tooltipMsg }}</div>
  </ng-container>
  <ng-container *ngIf="searchFields">
    <p>{{searchInfo ? searchInfo : 'Enter keywords for the following fields'}}:</p>
    <div class="d-flex no-gutters">
      <ng-container *ngFor="let col of searchFieldDisplay">
        <div class="col no-gutters">
          <ng-container *ngFor="let subRow of col">
            <div class="brownish-grey field-column">・{{ subRow }}</div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <!-- <ng-container *ngFor="let row of searchFieldDisplay;">
        <div class="row no-gutters">
            <ng-container *ngFor="let col of row;">
                <div class="brownish-grey col field-column">・{{col}}</div>
            </ng-container>
        </div>
    </ng-container> -->
</app-html-tooltip>
