import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData, DialogService, DropdownDictionary, DropdownService, Tenant, TenantService, untilDestroyed, UserService } from '@app/@core';
import { ReceiptTransactionService } from '@app/@core/services/receipt-transaction/receipt-transaction.service';
import { CurrencyMaskConstants } from '@app/@shared/masks/currency.mask';
import { pairwise, startWith, take } from 'rxjs/operators';
import { TransactionConstants } from '../../receipt-transaction.constant';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'receipt-verify-transaction-dialog',
  templateUrl: './verify-transaction-dialog.component.html',
  styleUrls: ['./verify-transaction-dialog.component.scss'],
})
export class VerifyTransactionDialogComponent implements OnInit, OnDestroy {
  public verifyTransactionFormGroup: UntypedFormGroup;
  public dropdownData: DropdownDictionary;
  public isDisableFailureReason = false;
  public tenantList: Tenant[];
  public DATE_TODAY = new Date();
  public HKD_MASK = CurrencyMaskConstants.HKD;
  public verifyButtonDisable: boolean = false;
  public isUpdateVerify: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VerifyTransactionDialogComponent>,
    private fb: UntypedFormBuilder,
    private receiptTransactionService: ReceiptTransactionService,
    private dropdownService: DropdownService,
    private tenantService: TenantService,
    private dialogService: DialogService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.dropdownService
      .getDropdown()
      .pipe(untilDestroyed(this))
      .subscribe((dropdownData) => {
        this.dropdownData = dropdownData;
      });

    this.tenantService.getTenantList().subscribe((tenantList: Tenant[]) => {
      this.tenantList = tenantList;
    });

    this.initFormGroup();
  }

  ngOnDestroy(): void {}

  /**
   * Init Dialog From Group
   */
  initFormGroup(): void {
    this.isUpdateVerify = this.data.verifyData ? true : false;
    let verifyData = this.data.verifyData || {
      verifyFailureReasons: [],
    };
    if(this.isUpdateVerify){
      verifyData = cloneDeep(verifyData);
    }
    this.verifyTransactionFormGroup = this.fb.group({
      isVerifyWithoutIssue: [verifyData.verifyStatus === TransactionConstants.VER_STATUS_VERIFIED_WITHOUT_ISSUE ? true : false],
      verifyStatus: [''],
      verifiedRemarks: [verifyData.verifiedRemarks ? verifyData.verifiedRemarks : ''],
      verifyFailureReasons: [verifyData.verifyFailureReasons.length > 0 ? verifyData.verifyFailureReasons : '', [Validators.required]],
      verifiedInvoiceNumbers: verifyData.verifiedInvoiceNumbers || [],
      verifiedReceiptAmount: verifyData.verifiedReceiptAmount || [],
      verifiedTenantId: verifyData.verifiedTenantId || [],
      verifiedTransactionAmount: verifyData.verifiedTransactionAmount || [],
      verifiedTransactionDate: verifyData.verifiedTransactionDate || [],
    });
    if (verifyData.verifyStatus === TransactionConstants.VER_STATUS_VERIFIED_WITHOUT_ISSUE) {
      this.verifyTransactionFormGroup.get('verifyFailureReasons').disable();
      this.verifyTransactionFormGroup.get('verifyFailureReasons').clearValidators();
      this.verifyTransactionFormGroup.get('verifyFailureReasons').setValue(null);
    }
    // update verification setValidators
    if(this.isUpdateVerify){
      Object.keys(TransactionConstants.VERIFY_REASON_MISMATCHED_MAP).forEach((key)=>{
        const controlKey = TransactionConstants.VERIFY_REASON_MISMATCHED_MAP[key];
        this.verifyTransactionFormGroup.get(controlKey).valueChanges.pipe(untilDestroyed(this),take(1)).subscribe((val) => {
          if(this.verifyFailureReasons?.value?.includes(key)){
            this.verifyTransactionFormGroup.get(controlKey).setValidators(Validators.required);
            this.verifyTransactionFormGroup.get(controlKey).updateValueAndValidity();
          }
        });
      })
    }

    this.verifyTransactionFormGroup
      .get('isVerifyWithoutIssue')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((isVerifyWithoutIssue: boolean) => {
        if (isVerifyWithoutIssue) {
          this.verifyTransactionFormGroup.get('verifyFailureReasons').disable();
          this.verifyTransactionFormGroup.get('verifyFailureReasons').clearValidators();
          this.verifyTransactionFormGroup.get('verifyFailureReasons').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedInvoiceNumbers').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedReceiptAmount').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedTenantId').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedTransactionAmount').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedTransactionDate').setValue(null);
        } else {
          this.verifyTransactionFormGroup.get('verifyFailureReasons').enable();
          this.verifyTransactionFormGroup.get('verifyFailureReasons').setValidators(Validators.required);
        }
        this.isDisableFailureReason = isVerifyWithoutIssue;
      });

    this.verifyTransactionFormGroup
      .get('verifyFailureReasons')
      .valueChanges.pipe(untilDestroyed(this), startWith([], []), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev?.some((item: any) => TransactionConstants.VERIFY_REASON_MISMATCHED.includes(item)) && !next?.some((item: any) => TransactionConstants.VERIFY_REASON_MISMATCHED.includes(item))) {
          this.verifyTransactionFormGroup.get('verifiedInvoiceNumbers').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedReceiptAmount').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedTenantId').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedTransactionAmount').setValue(null);
          this.verifyTransactionFormGroup.get('verifiedTransactionDate').setValue(null);
        }
        if ((!prev?.some((item: any) => TransactionConstants.VERIFY_REASON_MISMATCHED.includes(item)) && next?.some((item: any) => TransactionConstants.VERIFY_REASON_MISMATCHED.includes(item))) || (prev?.some((item: any) => TransactionConstants.VERIFY_REASON_MISMATCHED.includes(item)) && !next?.some((item: any) => TransactionConstants.VERIFY_REASON_MISMATCHED.includes(item)))) {
          window.dispatchEvent(new Event('resize'));
        }
        // verification setValidators
        this.verifyReasonMismathedCheck(next);
      });
  }

  /**
   * Submit Verify Transaction Request
   */
  submitVerifyApprovedTransactionRequest(): void {
    // TODO: Frontend Form Validation
    console.log(this.data.isPickupSlip);
    console.log(this.verifyTransactionFormGroup.get('verifiedReceiptAmount').value < 0);
    console.log(this.verifyTransactionFormGroup.get('verifiedTransactionAmount').value < 0);
    this.verifyButtonDisable = true;

    let spotcheckParams:any = null;
    if(this.data?.isSpotCheck) {
      const spotcheckReceiptData = JSON.parse(sessionStorage.getItem('spotcheckReceipt'));
      spotcheckParams = {
        dateFrom:spotcheckReceiptData?.dateFrom,
        dateTo: spotcheckReceiptData?.dateTo,
        isSpotCheckNext: true,
        spotCheckTargetConfigId: spotcheckReceiptData?.spotCheckTargetConfigId
      }
    }

    if (!this.data.isPickupSlip && (this.verifyTransactionFormGroup.get('verifiedReceiptAmount').value < 0 || this.verifyTransactionFormGroup.get('verifiedTransactionAmount').value < 0)) {
      const errorObj = {};
      errorObj['RECEIPT_TRANSACTIONS.ERROR.NEGATIVE_AMOUNT_IN_TRANSACTION'] = [];
      const dialogData: DialogData = {
        title: 'Error',
        errorList: errorObj,
        width: '370px',
        yesCallback: (dialogRef) => {
          this.verifyButtonDisable = false;
          dialogRef.close();
        },
      };
      this.dialogService.showErrorDialog(dialogData).subscribe();
    } else {
      const verifyStatus = this.verifyTransactionFormGroup.get('isVerifyWithoutIssue').value ? TransactionConstants.VER_STATUS_VERIFIED_WITHOUT_ISSUE : TransactionConstants.VER_STATUS_VERIFIED_WITH_ISSUE;
      const params = {
        id: this.data.receiptTransactionId,
        verifiedRemarks: this.verifyTransactionFormGroup.get('verifiedRemarks').value,
        verifyFailureReasons: this.verifyTransactionFormGroup.get('verifyFailureReasons').value,
        verifiedInvoiceNumbers: this.verifyTransactionFormGroup.get('verifiedInvoiceNumbers').value,
        verifiedReceiptAmount: this.verifyTransactionFormGroup.get('verifiedReceiptAmount').value,
        verifiedTenantId: this.verifyTransactionFormGroup.get('verifiedTenantId').value,
        verifiedTransactionAmount: this.verifyTransactionFormGroup.get('verifiedTransactionAmount').value,
        verifiedTransactionDate: this.verifyTransactionFormGroup.get('verifiedTransactionDate').value,
        verifyStatus,
        ...spotcheckParams
      };
      this.receiptTransactionService.verifyApprovedTransaction(params).subscribe({
        next:(res:any)=>{
          this.verifyButtonDisable = false;
          // this.dialogRef.close({ verifyStatus });
          this.dialogRef.close({ verifyStatus, finishSpotCheckTarget: res?.finishSpotCheckTarget });
        },
        error:()=>{
          this.verifyButtonDisable = false;
        }
      });
    }
  }

  isRequired(fieldName: string): boolean {
    return this.verifyTransactionFormGroup.get(fieldName)?.hasError('required');
  }

  requiredInputInvalid(fieldName: string): boolean {
    const control = this.verifyTransactionFormGroup.get(fieldName);
    return control && control.hasError('required') && control.touched;
  }

  verifyReasonMismathedCheck(next:any){
    Object.keys(TransactionConstants.VERIFY_REASON_MISMATCHED_MAP).forEach((key)=>{
      const controlKey = TransactionConstants.VERIFY_REASON_MISMATCHED_MAP[key];
      if(next && next?.includes(key)){
        this.verifyTransactionFormGroup.get(controlKey).setValidators(Validators.required);
      }else{
        this.verifyTransactionFormGroup.get(controlKey).setValidators(null);
      }
      this.verifyTransactionFormGroup.get(controlKey).updateValueAndValidity();
    })
  }

  onClickCloseButton(): void {
    this.dialogRef.close();
  }

  get isShowTransactionDetailSection(): boolean {
    const verifyFailureReasons = this.verifyTransactionFormGroup.get('verifyFailureReasons').value;
    return verifyFailureReasons && verifyFailureReasons?.some((item: any) => TransactionConstants.VERIFY_REASON_MISMATCHED.includes(item));
  }

  get verifiedReceiptAmount() {
    return this.verifyTransactionFormGroup.get('verifiedReceiptAmount');
  }

  get verifiedTransactionAmount() {
    return this.verifyTransactionFormGroup.get('verifiedTransactionAmount');
  }

  get verifyFailureReasons() {
    return this.verifyTransactionFormGroup.get('verifyFailureReasons');
  }

  get haveDepositTransactionAccess(): boolean {
    return this.userService.hasPermission('ui#loyalty#transaction#depositTransaction');
  }
}
