<mat-chip-grid #chipList class="app-chip-input">
  <!-- [selectable]="selectable" -->
  <mat-chip-row *ngFor="let str of value" [removable]="removable && !readonly" (removed)="remove(str)">
    <div class="content">
      {{ str }}
    </div>
    <mat-icon matChipRemove *ngIf="removable && !readonly && !disabled">close</mat-icon>
  </mat-chip-row>
  <input
    [placeholder]="placeholder"
    [disabled]="disabled || readonly"
    [readonly]="disabled || readonly"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [matChipInputAddOnBlur]="addOnBlur"
    (matChipInputTokenEnd)="add($event)"
    (input)="onInputChange($event)"
    autocomplete="off"
    maxlength="50"
    required
  />
</mat-chip-grid>
<!-- <mat-error *ngIf="chipList.errorState">Too many chips</mat-error> -->
