import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DialogData,
  DialogService,
  ImageListItem,
  LoyaltyService,
  ReceiptImageService,
  ReceiptTransaction,
  ReceiptTransactionService,
  ReimburseDetail,
  ReimburseImage,
  ReimbursementEvoucher,
  untilDestroyed,
} from '@app/@core';
import { CurrencyMaskConstants, DateMaskConstants, DateTimeMaskConstants, ImageUploaderComponent, ProcessedReceiptImage, SystemCurrencyPipe, maxAmountValidator } from '@app/@shared';
import { BlobOptions } from 'buffer';
import { assign, cloneDeep, find, keyBy } from 'lodash';
import { ReimbursementConstants } from '../../reimbursement.constants';
import { VALIDATOR } from 'angular2-query-builder';
import { DomSanitizer } from '@angular/platform-browser';
import { ReimbursementService } from '@app/@core/services/reimbursement/reimbursement.service';
import { finalize, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { NavigationExtras, Router } from '@angular/router';
import { ReimbursementHistoryDialogComponent } from '../reimbursement-history-dialog/reimbursement-history-dialog.component';
import { DropdownService } from '../../../../@core/services/application/generic-category/dropdown.service';
import { RevertReimbursementDialogComponent } from '../reimbursement-revert-dialog/reimbursement-revert-dialog.component';
import { TransactionConstants } from '../../../../@shared/components/receipt-transaction/receipt-transaction.constant';
import { VoidReimbursementDialogComponent } from '../reimbursement-void-dialog/reimbursement-void-dialog.component';
import { FeatureFlagService } from '@core/services/feature-flag.service';

@Component({
  selector: 'app-reimbursement-summary-dialog',
  templateUrl: './reimbursement-summary-dialog.component.html',
  styleUrls: ['./reimbursement-summary-dialog.component.scss'],
})
export class ReimbursementSummaryDialogComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('duplicatedViewRef') duplicatedViewRef: TemplateRef<any>;
  @Input() transactionId = '';
  @Input() isRequest = false;
  afterSave: EventEmitter<any> = new EventEmitter<any>();
  reimburseSummaryForm: UntypedFormGroup;
  imageViewerId: string = 'ReimbursementImageView';
  receiptImages: any[] = [];
  transactionDetail: ReceiptTransaction;
  isSubmitting: boolean;
  reimbursementDetail: any = {
    reimbursementImages: []
  };
  reimbursementSummaryId: string;
  selectedReimbursementEvoucher: ReimbursementEvoucher;
  HKD_MASK = CurrencyMaskConstants.HKD;
  canAddReceiptImage: boolean = false;
  DATE_MASK = DateMaskConstants.DateMask;
  selectedImage: ImageListItem;
  currentIndex: number = 0;
  isEdit: boolean;
  deleteImages: ReimburseImage[] = [];
  reimbursementId: any;
  revertButtonShow: boolean = false;
  duplicateFlag: any = false;
  invoiceNumberDataSource: any[] = [];
  reimbursementTypeList: any;
  businessUnit = '';
  isShowVoidButton = false;
  isRPT = false;
  isPaymentTerminal = false;
  isShowEditAndRevertBtn = true;
  data: any;
  redemptionChannel = '';
  get imageService() {
    return this.injector.get(ReceiptImageService);
  }
  public dialogRef: MatDialogRef<ReimbursementSummaryDialogComponent> | null = null;
  constructor(
    private dialogService: DialogService,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private injector: Injector,
    // public dialogRef: MatDialogRef<ReimbursementSummaryDialogComponent>,
    private loyaltyService: LoyaltyService,
    // private imageService: ReceiptImageService,
    private domSanitizer: DomSanitizer,
    private reimbursementService: ReimbursementService,
    private translateService: TranslateService,
    protected systemCurrencyPipe: SystemCurrencyPipe,
    private router: Router,
    private dropdownService: DropdownService,
    private cd: ChangeDetectorRef,
    public featureFlagService: FeatureFlagService
  ) {
    this.reimburseSummaryForm = this.fb.group({
      invoiceNumber: ['', [Validators.required]],
      serialNumber: [],
      spendingAmount: [null, { updateOn: 'blur' }],
      transactionAmount: [null, { updateOn: 'blur' }],
    });
    this.businessUnit = this.loyaltyService.currentProgramme.businessUnit;
    this.data = this.injector.get(MAT_DIALOG_DATA, null);
    this.dialogRef = this.injector.get(MatDialogRef, null);
    if(this.data) {
      this.transactionId = this.data.detail.transactionId;
      this.inintData(this.data.detail, true)
    }else {
      if (!this.reimbursementDetail?.reimbursementImages) {
        this.reimbursementDetail.reimbursementImages = [];
      }
    }
  }

  setRedemptionChannel(giftId?:any) {
    const typeList:any = this.reimbursementDetail.transactionType == "E_VOUCHER" ? 'evouchers ' :'mallDollar';
    if (typeList.indexOf('evouchers')> -1  && this.reimbursementDetail.evouchers?.length) {
      let index = 0;
      giftId && (index = this.reimbursementDetail.evouchers.findIndex((item:any) => item.giftId == giftId ))
      this.redemptionChannel = this.reimbursementDetail?.evouchers[index]?.redemptionChannel;
    } else {
      this.redemptionChannel = this.reimbursementDetail?.mallDollar?.redemptionChannel;
    }
  }
  inintData(data:any, isEdit= false) {
      this.reimbursementDetail = data;
      this.setRedemptionChannel()
      
      if (!this.reimbursementDetail?.reimbursementImages) {
        this.reimbursementDetail.reimbursementImages = [];
      }
      this.reimburseSummaryForm.patchValue(data);
      let isNoCrash = false;
      let isfullySettleByActualization = false;
      if(data.hasOwnProperty('receiptTransactionVos') && data?.receiptTransactionVos?.length) {
        isNoCrash = data?.receiptTransactionVos.every((item:any) => item.paymentMethod != 'CASH');
        isfullySettleByActualization = data?.receiptTransactionVos.some((item:any) => {
          const num =  Number(item?.originalTransactionAmount) - Number(item?.rptDiscountedAmount) - Number(item?.rptMallDollarDiscountedAmount);
          return num > 0;
        })
      }
      this.isRPT = this.featureFlagService.getFeatureFlag('RPT') && data?.channel == 'PAYMENT_TERMINAL' && isNoCrash && isfullySettleByActualization
      this.isPaymentTerminal = this.featureFlagService.getFeatureFlag('RPT') && data?.channel == 'PAYMENT_TERMINAL';
      this.isShowEditAndRevertBtn = (this.featureFlagService.getFeatureFlag('RPT') && data?.channel == 'PAYMENT_TERMINAL' && (!isNoCrash || !isfullySettleByActualization)) || (data?.channel != 'PAYMENT_TERMINAL')
      this.revertButtonShow = data?.isEligibleForRevert;

      // todo
      this.isEdit = isEdit ? this.data?.isEdit: false;
      this.duplicateFlag = data.duplicateFlag;
      const reimbursementSummary = data?.reimbursementSummary as any;
      if(data?.reimbursementSummary) {
        this.reimbursementSummaryId = reimbursementSummary?.endorsementId ?? reimbursementSummary?.id;
      }
   
      if(data.status == "SUBMITTED" && reimbursementSummary?.endorsementId && this.reimbursementDetail?.reimbursementSummary?.status != ReimbursementConstants.STATUS_REJECTED) {
        this.isShowVoidButton = false;
      }else if(['SUBMITTED',ReimbursementConstants.STATUS_REVERTED,ReimbursementConstants.STATUS_PENDING,ReimbursementConstants.STATUS_EXPIRED].includes(data?.status)) {
        this.isShowVoidButton = true;
      }

      if (this.isMallDollar) {
        // to do mall dollar
      } else {
        this.selectedReimbursementEvoucher = this.reimbursementDetail?.evouchers[0];
        this.serialNumber?.setValue(this.selectedReimbursementEvoucher.giftId);
      }
  
      this.initControl();
      this.subscribeImageChange();
      this.subscribeFormValueChange();
      if(data?.channel != 'PAYMENT_TERMINAL' && !this.isRequest && this.reimbursementDetail?.isEditable) {
        this.spendingAmount?.enable();
      }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.transactionId?.currentValue) {
      this.reimburseSummaryForm.disable();
      this.init(changes?.transactionId?.currentValue)
    }
  }
  init(id:any) {
    this.reimbursementService.getReimbursementDetail(id).subscribe((res:any) => {
      res.spendingAmount = res.spendingAmount.toString()
      this.inintData(res);
      this.cd.detectChanges();

    })
  }
  closeDuplicated() {
    this.dialogService.closeDialog('duplicated-dialog');
  }
  changeTo(val:any) {
    const index = val?.receiptTransactionVos.findIndex((item:any) =>  !item.parentReceiptTransactionId)
    const receiptTransactionIndex = index>=0? index : 0
    window.open(`/admin/receipt-transaction/view/${val?.receiptTransactionVos[receiptTransactionIndex]?.id}`, '_blank');
    
  }
  onClickDuplicated() {
    this.reimbursementService
      .getInvoiceNumber({
        invoiceNumber: this.reimbursementDetail?.invoiceNumber,
        id: this.reimbursementDetail?.transactionId,
      })
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.invoiceNumberDataSource = res;
        const dialogData: DialogData = {
          id: 'duplicated-dialog',
          content: this.duplicatedViewRef,
          width: '500px',
          height: 'auto',
        };
        this.dialogService.showDialog(dialogData).subscribe();
      });
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.reimbursementTypeList = keyBy(ReimbursementConstants.TRANSACTION_TYPE_LIST, 'value');
   
    this.spendingAmount.setValidators([Validators.required, maxAmountValidator]);
    this.transactionAmount.setValidators([Validators.required, maxAmountValidator]);
      
    // if (this.isMallDollar) {
    //   // to do mall dollar
    // } else {
    //   this.selectedReimbursementEvoucher = this.reimbursementDetail?.evouchers[0];
    //   this.serialNumber.setValue(this.selectedReimbursementEvoucher.giftId);
    // }

    // this.initControl();
    // this.subscribeImageChange();
    // this.subscribeFormValueChange();

  }
  changeReimbursement(val: any) {
    const endorsementId = val.data.endorsementId ? val.data.endorsementId : val.data.reimbursementSummaryId;
    window.open(`/admin/reimbursement/list?duplicatedTransactionId=${val.data?.id}&endorsementId=${endorsementId ? endorsementId : ''}`, '_blank');
  }
  voidButtonClick() {
    this.dropdownService
    .getDropdown()
    .pipe(take(1))
    .subscribe(
      (dropdownData) => {
        const dialogData = {
          id: 'reimbursement-revert-dialog',
          width: '490px',
          panelClass: 'outer-dialog-container',
          data: {
            dropdownData: dropdownData,
            transactionId: this.transactionId,
            guestId: this.reimbursementDetail.memberId
          },
          disableClose: true,
          hasBackdrop: true,
          autoFocus: false,
        };

        const dialogRefObservable = this.dialogService.showDialog(dialogData, VoidReimbursementDialogComponent);
        dialogRefObservable.subscribe(({ id: dialogId, componentInstance: dialogInstance }) => {
          dialogInstance.afterSave.subscribe((res: boolean) => {
            if (res) {
              this.reimbursementService.revertReimbursementSuccessSubject(null);
            }
          });
        });
      })
  }
  revertButtonClick() {
    this.dropdownService
      .getDropdown()
      .pipe(take(1))
      .subscribe(
        (dropdown) => {
          const dropdownData = dropdown['REIMBURSEMENT']['REIMBURSEMENT_REVERT_REASON'].filter((item:any) => {
            return this.reimbursementDetail?.status == TransactionConstants.STATUS_SUBMITTED ? item.value != "TRANSACTION_IS_EXPIRED" : item.value == "TRANSACTION_IS_EXPIRED"
          });

          const dialogData = {
            id: 'reimbursement-revert-dialog',
            width: '490px',
            panelClass: 'outer-dialog-container',
            data: { dropdownData, isPaymentTerminal: this.isPaymentTerminal },
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
          };

          const dialogRefObservable = this.dialogService.showDialog(dialogData, RevertReimbursementDialogComponent);
          dialogRefObservable.subscribe(({ id: dialogId, componentInstance: dialogInstance }) => {
            dialogInstance.afterSave.subscribe((res: boolean) => {
              if (res) {
                this.revertConfirmDialog(res);
              }
            });
          });
        },
        (err) => {
          console.log(err);
        }
      );
  }

  revertConfirmDialog(data: any) {
    const revertDialogData: DialogData = {
      content: 'After returning to pending, all input will be cleared and returned to the tenant app. Are you sure you want to return?',
      yesLabel: 'CONFIRM',
      yesCallback: (dialogRef) => {
        const params = {
          remark: data.remark,
          reasons: data.reasons.join(','),
          transactionId: this.reimbursementDetail.transactionId,
        };
        this.reimbursementService.revertReimbursement(params).subscribe(
          () => {
            dialogRef.close();
            this.reimbursementService.revertReimbursementSuccessSubject(null);
          },
          () => {
            dialogRef.close();
          }
        );
      },
      noLabel: 'CANCEL',
      noCallback: (dialogRef) => {
        dialogRef.close();
      },
    };
    this.dialogService.showConfirmationDialog(revertDialogData).subscribe();
  }

  onClickSave() {
    if (this.isValidForm()) {
      const dialogData: DialogData = {
        content: 'Save the item?',
        yesLabel: 'CONFIRM',
        yesCallback: (dialogRef) => {
          this.saveHandler();
          dialogRef.close();
        },
        noLabel: 'CANCEL',
        noCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showConfirmationDialog(dialogData).subscribe();
    }
  }
  isValidForm() {
    this.reimburseSummaryForm.markAllAsTouched();
    this.reimburseSummaryForm.updateValueAndValidity();
    const errorObj = {};
    const errorFields: string[] = [];
    if (this.reimbursementImageCount == 0) {
      errorFields.push(`${this.translateService.instant('MEMBER.SELECT.IMAGES.MISSING')}`);
    }
    if (!this.reimburseSummaryForm.valid || this.reimbursementImageCount == 0) {
      Object.keys(this.reimburseSummaryForm.controls).forEach((key) => {
        const controlErrors: ValidationErrors = this.reimburseSummaryForm.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            let fieldName = this.translateService.instant(`REIMBURSEMENT.FIELD.${key.toUpperCase()}`);
            if (key.toUpperCase() == 'TRANSACTIONAMOUNT') {
              if (this.isMallDollar) fieldName = 'Mall Dollar Amount';
              else fieldName = 'eVoucher Total Amount';
            }
            let errorLabel;
            switch (keyError) {
              case 'invalidInput':
                errorLabel = this.translateService.instant(`RECEIPT_TRANSACTIONS.ERROR.INVALID_AMOUNT_INPUT`);
                break;
              case 'required':
                errorLabel = this.translateService.instant(`MEMBER.ERROR.required`);
                break;
              case 'outOfMaxAmount':
                errorLabel = this.translateService.instant(`COMMON.MESSAGE.OUT_OF_MAX_AMOUNT`, { maxAmount: this.systemCurrencyPipe.transform(environment.APP_CONFIG.MAX_AMOUNT) });
                break;
              case 'notEligibleToEarnPoints':
                errorLabel = this.translateService.instant(`RECEIPT_TRANSACTIONS.ERROR.UPLOAD_RECEIPT`);
                break;
              default:
                errorLabel = this.translateService.instant(`COMMON.MESSAGE.ERROR_IS_INVALID`);
                break;
            }
            errorFields.push(`${fieldName} ${errorLabel}`);
          });
        }
      });

      errorObj['COMMON.MESSAGE.INVALID_FIELD'] = errorFields;
      const dialogData: DialogData = {
        title: 'COMMON.MESSAGE.ERROR',
        errorList: errorObj,
        width: '370px',
        yesCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showErrorDialog(dialogData).subscribe();
      return false;
    } else {
      return true;
    }
  }
  saveHandler() {
    let currentDetail = cloneDeep(this.reimbursementDetail);
    const detailInfo = Object.assign(currentDetail, this.reimburseSummaryForm.getRawValue());
    if (this.deleteImages.length > 0) {
      console.log(this.deleteImages);
      this.deleteImages.forEach((item) => {
        detailInfo.reimbursementImages.push(item);
      });
    }
    this.isSubmitting = true;
    this.reimbursementService
      .updateDetail(detailInfo)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        console.log(res);
        this.reimburseSummaryForm.markAsPristine();
        this.afterSave.emit(true);
        this.dialogRef?.close(true);
      });
  }

  onClickReturn() {
    if (!this.isSubmitting) {
      const dialogData: DialogData = {
        content: `After returning to pending, all input will be cleared and returned to the tenant app. Are you sure you want to return?`,
        id: 'confirm-return-dialog',
        title: '',

        yesLabel: 'Confirm',
        noLabel: 'Cancel',
        yesCallback: (dialogRef) => {
          dialogRef.close();
          this.dialogRef?.close();
        },
        noCallback: (dialogRef) => {
          dialogRef.close();
        },
      };
      this.dialogService.showConfirmationDialog(dialogData).subscribe();
    }
  }
  onClickEdit() {
    this.isEdit = true;
    this.enableEditableField();
  }
  onDeleteImage($event: any): void {
    let deletedItem = this.reimbursementDetail?.reimbursementImages?.filter((item:any) => item.uuid == $event.id)[0];
    deletedItem.isRemoved = true;
    this.deleteImages.push(deletedItem);
    console.log(this.deleteImages);
    this.reimbursementDetail.reimbursementImages = this.reimbursementDetail?.reimbursementImages?.filter((item:any) => item.uuid != $event.id);
    console.log(this.reimbursementDetail.reimbursementImages);
  }

  onChangeImageCallback(event: any) {
    console.log(event);
  }
  initControl() {
    if (this.canSave && this.isEdit) {
      this.enableEditableField();
    } else {
      this.disableEditableField();
    }
  }
  getFieldByName(fieldName: string) {
    return this.reimburseSummaryForm.get(fieldName);
  }
  subscribeFormValueChange() {
    this.serialNumber.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      console.log(value);
      if (value !== null && value !== '') {
        const evoucher = find(this.reimbursementDetail.evouchers, (v: ReimbursementEvoucher) => v.giftId == value);
        this.selectedReimbursementEvoucher = evoucher;

        this.setRedemptionChannel(value)
        // this.serialNumber.setValue(this.selectedReimbursementEvoucher.giftId);
      }
    });
  }
  subscribeImageChange() {
    this.imageService.selectedImage.pipe(untilDestroyed(this)).subscribe((selectedImage) => {
      if (selectedImage && this.imageViewerId === selectedImage.imageViewerId) {
        this.selectedImage = selectedImage.imageSrc;
        if (this.activeImages && this.selectedImage) {
          this.currentIndex = this.activeImages.findIndex((x:any) => x.uuid == this.selectedImage.id);
        }
      }
    });
    this.reimbursementService.imageUploadChange.pipe(untilDestroyed(this)).subscribe((res: ReimburseImage) => {
      if (res) {
        let imgs = cloneDeep(this.reimbursementDetail?.reimbursementImages);
        imgs.push(res);
        this.reimbursementDetail.reimbursementImages = imgs;
      }
    });
  }
  serialNumberChange(event: any) {
    console.log(event);
    const evoucher = find(this.reimbursementDetail.evouchers, (v: ReimbursementEvoucher) => v.giftId == event.value);
    this.selectedReimbursementEvoucher = evoucher;
    this.serialNumber.setValue(this.selectedReimbursementEvoucher.giftId);
  }
  get invoiceNumber() {
    return this.reimburseSummaryForm.get('invoiceNumber');
  }
  get spendingAmount() {
    return this.reimburseSummaryForm.get('spendingAmount');
  }
  get transactionDate() {
    return this.reimburseSummaryForm.get('transactionDate');
  }
  get memberId() {
    return this.reimburseSummaryForm.get('memberId');
  }
  get reimbursementAmount() {
    return this.reimburseSummaryForm.get('reimbursementAmount');
  }
  get isMallDollar() {
    return this.reimbursementDetail.transactionType == 'MALL_DOLLAR';
  }
  get baseRate() {
    return this.reimburseSummaryForm.get('promotionName');
  }
  get promotionRate() {
    return this.reimburseSummaryForm.get('promotionRate');
  }
  get promotionName() {
    return this.reimburseSummaryForm.get('promotionName');
  }
  get giftName() {
    return this.reimburseSummaryForm.get('giftName');
  }
  get serialNumber() {
    return this.reimburseSummaryForm.get('serialNumber');
  }
  get reimbursementImageCount() {
    return this.activeImages.length;
  }
  get transactionAmount() {
    return this.reimburseSummaryForm.get('transactionAmount');
  }
  get canSave() {
    return this.reimbursementDetail?.status === 'SUBMITTED';
  }
  get activeImages() {
    return this.reimbursementDetail?.reimbursementImages?.filter((item:any) => !item.isRemoved);
  }
  goToImage(action: string) {
    if (this.selectedImage) {
      this.currentIndex = this.reimbursementDetail?.reimbursementImages.findIndex((x:any) => x.uuid == this.selectedImage.id);
      let image;
      if (action == 'next') {
        this.currentIndex = this.currentIndex + 1;
      } else {
        this.currentIndex = this.currentIndex - 1;
      }
      image = this.reimbursementDetail?.reimbursementImages[this.currentIndex];
      this.selectedImage = {
        id: image.uuid,
        thumbnailUrl: image.thumbnailPath,
        originalUrl: image.imgPath,
        caption: '',
      };
      this.imageService.setSelectedViewerImage(this.selectedImage, this.imageViewerId);
    } else {
    }
  }
  enableEditableField() {
    this.invoiceNumber.enable();
    !this.isPaymentTerminal && this.spendingAmount.enable();
    // this.transactionAmount.enable();
    // this.serialNumber.enable();
  }
  disableEditableField() {
    this.invoiceNumber.disable();
    this.spendingAmount.disable();
    // this.transactionAmount.disable();
    // this.serialNumber.disable();
  }
  navigateToRedemptionActivities() {
    const url = `admin/members/view/${this.reimbursementDetail.memberId}?transactionNumber=${this.reimbursementDetail.actualizationTxn}#tabIndex=2&subTabIndex=5`;
    window.open(url, '_blank', '');
  }
  onClickHistory(): void {
    this.dropdownService
      .getDropdown()
      .pipe(take(1))
      .subscribe(
        (dropdown) => {
          const dropdownData = dropdown['REIMBURSEMENT']['REIMBURSEMENT_REVERT_REASON'];
          const dialogData = {
            id: 'reimbursement-history-dialog',
            width: '800px',
            height: '350px',
            panelClass: 'outer-dialog-container',
            data: {
              summaryId: this.reimbursementDetail.transactionId,
              actionType: 'REIMBURSEMENT',
              dropdownData,
            },
            disableClose: true,
            hasBackdrop: true,
            autoFocus: false,
          };

          const dialogRefObservable = this.dialogService.showDialog(dialogData, ReimbursementHistoryDialogComponent);
          dialogRefObservable.subscribe(({ id: dialogId, componentInstance: dialogInstance }) => {
            // dialogInstance.afterSubmit.subscribe((res: boolean) => {
            //   if (res) {
            //     this.dialogService.closeDialog(dialogId);
            //   }
            // });
          });
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
